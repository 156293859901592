import { addLocaleData } from 'react-intl';
import enLang from './entries/en-US';
import thLang from './entries/th-TH';
// import enRtlLang from './entries/en-US-rtl';

const AppLocale = {
    en: enLang,
    th: thLang,
    // enrtl:enRtlLang
};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.th.data);

export default AppLocale;

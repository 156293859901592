import appLocaleData from 'react-intl/locale-data/th';
import esMessages from '../locales/th_TH';

const ThLang = {
    messages: {
        ...esMessages
    },
    locale: 'th-TH',
    data: appLocaleData
};
export default ThLang;
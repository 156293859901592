/* SETTINGS */
// ==========Province=============
export const PROVINCE = "PROVINCE";
export const PROVINCE_SUCCESS = "PROVINCE_SUCCESS";
export const DISTRICT = "DISTRICT";
export const DISTRICT_SUCCESS = "DISTRICT_SUCCESS";
export const SUBDISTRICT = "PROFILE_SUBDISTRICT";
export const SUBDISTRICT_SUCCESS = "SUBDISTRICT_SUCCESS";

export const CREDITBANK_GET = "CREDITBANK_GET";
export const CREDITBANK_GET_SUCCESS = "CREDITBANK_GET_SUCCESS";

export const BANK_PAYMENT_GET = "BANK_PAYMENT_GET";
export const BANK_PAYMENT_GET_SUCCESS = "BANK_PAYMENT_GET_SUCCESS";

export const WIDTHHOLDINGTAX_GET = "WIDTHHOLDINGTAX_GET";
export const WIDTHHOLDINGTAX_GET_SUCCESS = "WIDTHHOLDINGTAX_GET_SUCCESS";

export const CUSTOMER_INVOICE_GET = "CUSTOMER_INVOICE_GET";
export const CUSTOMER_INVOICE_GET_SUCCESS = "CUSTOMER_INVOICE_GET_SUCCESS";
export const NAME_TITLE_GET = "NAME_TITLE_GET";
export const NAME_TITLE_GET_SUCCESS = "NAME_TITLE_GET_SUCCESS";

export const OMISE_SEND = "OMISE_SEND";
export const OMISE_SEND_CUSTOMER = "OMISE_SEND_CUSTOMER";
export const OMISE_SEND_SUCCESS = "OMISE_SEND_SUCCESS";
export const BILL_SLIP_SEND = "BILL_SLIP_SEND";
export const BILL_SLIP_INVOICE_SEND = "BILL_SLIP_INVOICE_SEND";

// ==========Company=============
export const CHANGE_LOCALE = "CHANGE_LOCALE";
export const COMPANY_PROFILE_ADD = "COMPANY_PROFILE_ADD";
export const COMPANY_PROFILE_ADD_SUCCESS = "COMPANY_PROFILE_ADD_SUCCESS";
export const COMPANY_PROFILE_GET = "COMPANY_PROFILE_GET";
export const COMPANY_PROFILE_GET_SUCCESS = "COMPANY_PROFILE_GET_SUCCESS";
export const COMPANY_PROFILE_UPDATE = "COMPANY_PROFILE_UPDATE";
export const COMPANY_PROFILE_UPDATE_SUCCESS = "COMPANY_PROFILE_UPDATE_SUCCESS";
export const COMPANY_PROFILE_DELETE = "COMPANY_PROFILE_DELETE";
export const COMPANY_PROFILE_DELETE_SUCCESS = "COMPANY_PROFILE_DELETE_SUCCESS";
export const COMPANY_PROFILE_COUNTRY = "COMPANY_PROFILE_COUNTRY";
export const COMPANY_PROFILE_COUNTRY_SUCCESS =
  "COMPANY_PROFILE_COUNTRY_SUCCESS";
export const COMPANY_PROFILE_PROVINCE = "COMPANY_PROFILE_PROVINCE";
export const COMPANY_PROFILE_PROVINCE_SUCCESS =
  "COMPANY_PROFILE_PROVINCE_SUCCESS";
export const COMPANY_PROFILE_DISTRICT = "COMPANY_PROFILE_DISTRICT";
export const COMPANY_PROFILE_DISTRICT_SUCCESS =
  "COMPANY_PROFILE_DISTRICT_SUCCESS";
export const COMPANY_PROFILE_SUBDISTRICT = "COMPANY_PROFILE_SUBDISTRICT";
export const COMPANY_PROFILE_SUBDISTRICT_SUCCESS =
  "COMPANY_PROFILE_SUBDISTRICT_SUCCESS";
// export const COMPANY_PROFILE_ZIPCODE = 'COMPANY_PROFILE_ZIPCODE';
// export const COMPANY_PROFILE_ZIPCODE_SUCCESS = 'COMPANY_PROFILE_ZIPCODE_SUCCESS';
export const COMPANY_PKINDEV = "COMPANY_PKINDEV";
export const COMPANY_PKINDEV_SUCCESS = "COMPANY_PKINDEV_SUCCESS";
// ========Project=========
export const PROJECT_ADD = "PROJECT_ADD";
export const PROJECT_SET = "PROJECT_SET";
export const PROJECT_SET_SUCCESS = "PROJECT_SET_SUCCESS";
export const PROJECT_ADD_SUCCESS = "PROJECT_ADD_SUCCESS";
export const PROJECT_GET = "PROJECT_GET";
export const PROJECT_GET_INVOICE = "PROJECT_GET_INVOICE";
export const PROJECT_GET_SUCCESS = "PROJECT_GET_SUCCESS";
export const PROJECT_UPDATE = "PROJECT_UPDATE";
export const PROJECT_UPDATE_SUCCESS = "PROJECT_UPDATE_SUCCESS";
export const PROJECT_DELETE = "PROJECT_DELETE";
export const PROJECT_DELETE_SUCCESS = "PROJECT_DELETE_SUCCESS";

// ========User=========
export const USER_GET = "USER_GET";
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USER_ADD = "USER_ADD";
export const USER_UPDATE = "USER_UPDATE";
export const USER_PASS_UPDATE = "USER_PASS_UPDATE";
export const USER_DELETE = "USER_DELETE";
export const ROLE_GET = "ROLE_GET";
export const ROLE_GET_SUCCESS = "ROLE_GET_SUCCESS";
export const ROLE_ADD = "ROLE_ADD";
export const ROLE_ADD_SUCCESS = "ROLE_ADD_SUCCESS";
export const ROLE_UPDATE = "ROLE_UPDATE";
export const ROLE_UPDATE_SUCCESS = "ROLE_UPDATE_SUCCESS";
export const ROLE_DELETE = "ROLE_DELETE";
export const SEND_VERIFY = "SEND_VERIFY";
export const SEND_REST_PASSWORD = "SEND_REST_PASSWORD";
export const USER_GET_EMAIL = "USER_GET_EMAIL";
export const USER_GET_EMAIL_SUCCESS = "USER_GET_EMAIL_SUCCESS";
export const CONFIRM_VERIFY = "CONFIRM_VERIFY";
export const CONFIRM_VERIFY_STATUS = "CONFIRM_VERIFY_STATUS";
export const SEND_REQUEST = "SEND_REQUEST";

// ========Payment=========
export const PAYMENT_GET = "PAYMENT_GET";
export const PAYMENT_GET_SUCCESS = "PAYMENT_GET_SUCCESS";
export const PAYMENT_ADD = "PAYMENT_ADD";
export const PAYMENT_DELETE = "PAYMENT_DELETE";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* AUTH */
export const AUTH_USER = "AUTH_USER";
export const AUTH_USER_SUCCESS = "AUTH_USER_SUCCESS";

export * from "./auth/actions";
export * from "./menu/actions";
export * from "./settings/actions";
export * from "./customerInvoice/actions";

import React, { useState, useEffect, useRef } from "react";
import printJS from "print-js";
import { toAbsoluteUrl } from "../../helpers/Utils";
import {
  Modal,
  Button,
  Card,
  Col,
  Row,
  Container,
  OverlayTrigger,
  Tooltip,
  Overlay,
} from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import styled from "styled-components";
import PDF from "react-pdf-js";

const StyledPagination = styled(Pagination)`
  .MuiPagination-ul {
    button {
      color: #003581;
      font-size: 15px;
      border-radius: 10px;
    }
    button.Mui-selected {
      color: white;
      background-color: #d33d4c;
    }
  }
`;
const StyledWrapper1 = styled.div`
  .show_document {
    max-height: 500px;
    max-width: 700px;
  }
  @media (max-width: 1200px) {
    .show_document {
      max-width: 700px;
    }
  }
  @media (max-width: 990px) {
    .show_document {
      max-width: 400px;
    }
  }
  @media (max-width: 576px) {
    .show_document {
      width: 100%;
    }
  }
`;
export const ModalviewPDF = (props) => {
  const [countPage, setcountPage] = useState(0);
  const [pageNumber, setpageNumber] = useState(1);

  useEffect(() => {
    if (!props.show) {
      setpageNumber(1);
      setcountPage(0);
    }
  }, [props.show]);

  const urltoFile = (url, filename, mimeType) => {
    return fetch(url)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], filename, { type: mimeType }));
  };

  const print = (dataurl) => {
    urltoFile(
      dataurl,
      "test.pdf",
      dataurl.split(";base64,")[0].split("data:").join("")
    ).then((file) => {
      const localPdf = new Blob([file], { type: file.type });
      const urlPdf = URL.createObjectURL(localPdf);
      printJS({
        printable: urlPdf,
        type: `${file.type == "application/pdf" ? "pdf" : "image"}`,
      });
    });
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ color: "#003581" }}
        >
          Preview - {props.data.label}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <StyledWrapper1
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="show_document"
            style={{ border: "1px dotted black", overflow: "auto" }}
          >
            {props.data.url != null &&
            props.data.url.split(";base64,")[0].split("data:").join("") ==
              "application/pdf" ? (
              <PDF
                file={props.data.url}
                onDocumentComplete={(numPages) => setcountPage(numPages)}
                onPageComplete={0}
                page={pageNumber}
                workerSrc={toAbsoluteUrl("/js/pdf.worker.js")}
                scale={1.5}
              />
            ) : (
              <img src={props.data.url} style={{ width: "100%" }} />
            )}
          </div>
          <br />
          <StyledPagination
            count={countPage}
            shape=""
            onChange={(event, value) => setpageNumber(value)}
          />
        </StyledWrapper1>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={props.onHide}
          style={{ color: "#003581", borderRadius: "10px" }}
        >
          Close
        </Button>
        <Button
          variant="danger"
          onClick={() => print(props.data.url)}
          style={{ borderRadius: "10px" }}
        >
          Print
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

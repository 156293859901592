import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import auth from './auth/reducer'
import customerInvoice from './customerInvoice/reducer'

const reducers = combineReducers({
  auth,
  customerInvoice,
  menu,
  settings
});

export default reducers;
import React from 'react';
import styled from "styled-components";

export const SpinnerEffect = () => {
    const Spinner = styled.div`
    margin: 10px auto 0;
    width: 150px;
    text-align: center;
`
    const SpinnerSun = styled.div`
    width: 18px;
    height: 18px;
    background-color:#65265D;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
`
    const SpinBounce1 = styled(SpinnerSun)`
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
`

    const SpinBounce2 = styled(SpinnerSun)`
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
`
    return (
        <Spinner style={{marginBottom: "15px"}}>
            <SpinBounce1></SpinBounce1>
            <SpinBounce2></SpinBounce2>
            <SpinnerSun></SpinnerSun>
        </Spinner>
    )
}
import {
  CUSTOMER_INVOICE_GET,
  NAME_TITLE_GET,
  OMISE_SEND,
  OMISE_SEND_CUSTOMER,
  WIDTHHOLDINGTAX_GET,
  BILL_SLIP_SEND,
  BILL_SLIP_INVOICE_SEND,
  BANK_PAYMENT_GET,
} from "../actions";

import {
  getCustomerInvoiceSuccess,
  getNameTitleSuccess,
  getWidthHoldingTaxSuccess,
  getBankPaymentSuccess,
  sendOmiseSuccess,
} from "../customerInvoice/actions";

import apiSetting from "../../services/setting";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

function* getBankPayment({ payload }) {
  try {
    const dataRes = yield apiSetting.BankPayment(payload);

    if (dataRes.length != 0) {
      yield put(getBankPaymentSuccess(dataRes));
    } else {
      // yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getCustomerInvoice({ payload }) {
  try {
    const dataRes = yield apiSetting.CustomerInvoice(payload);

    if (dataRes.length != 0) {
      yield put(getCustomerInvoiceSuccess(dataRes));
    } else {
      // yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getWidthHoldingTax({ payload }) {
  try {
    const dataRes = yield apiSetting.WithHoldingTax(payload);

    if (dataRes.length != 0) {
      yield put(getWidthHoldingTaxSuccess(dataRes));
    } else {
      // yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getNameTitle({ payload }) {
  try {
    const dataRes = yield apiSetting.NameTitle(payload);

    if (dataRes.length != 0) {
      yield put(getNameTitleSuccess(dataRes));
    } else {
      // yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    console.log(error);
  }
}
function* sendOmise({ payload }) {
  // console.log(payload);
  try {
    const dataRes = yield apiSetting.SendOmise(payload);
    if (dataRes.length != 0) {
      yield put(sendOmiseSuccess(dataRes));
    } else {
      // yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    console.log(error);
  }
}
function* sendOmiseCustomer({ payload }) {
  // console.log(payload);
  try {
    const dataRes = yield apiSetting.SendOmiseCustomer(payload);
    if (dataRes.length != 0) {
      // yield put(sendOmiseSuccess(dataRes));
    } else {
      // yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    console.log(error);
  }
}
function* sendBillSlip({ payload }) {
  // console.log(payload);
  try {
    const dataRes = yield apiSetting.SendBillSlip(payload);
  } catch (error) {
    console.log(error);
  }
}
function* SendBillSlipInvoice({ payload }) {
  // console.log(payload);
  try {
    const dataRes = yield apiSetting.SendBillSlipInvoice(payload);
  } catch (error) {
    console.log(error);
  }
}

export function* watchgetCustomerInvoice() {
  yield takeEvery(CUSTOMER_INVOICE_GET, getCustomerInvoice);
}
export function* watchgetNameTitle() {
  yield takeEvery(NAME_TITLE_GET, getNameTitle);
}
export function* watchsendOmise() {
  yield takeEvery(OMISE_SEND, sendOmise);
}
export function* watchsendOmiseCustomer() {
  yield takeEvery(OMISE_SEND_CUSTOMER, sendOmiseCustomer);
}
export function* watchgetWidthHoldingTax() {
  yield takeEvery(WIDTHHOLDINGTAX_GET, getWidthHoldingTax);
}
export function* watchsendBillSlip() {
  yield takeEvery(BILL_SLIP_SEND, sendBillSlip);
}
export function* watchgetBankPayment() {
  yield takeEvery(BANK_PAYMENT_GET, getBankPayment);
}
export function* watchsendBillSlipInvoice() {
  yield takeEvery(BILL_SLIP_INVOICE_SEND, SendBillSlipInvoice);
}

export default function* rootSaga() {
  yield all([
    // =========AuthUser=======
    fork(watchgetCustomerInvoice),
    fork(watchgetNameTitle),
    fork(watchsendOmise),
    fork(watchsendOmiseCustomer),
    fork(watchgetWidthHoldingTax),
    fork(watchsendBillSlip),
    fork(watchgetBankPayment),
    fork(watchsendBillSlipInvoice),
  ]);
}

import {
    AUTH_USER,
    AUTH_USER_SUCCESS
} from '../actions'
import jwt_decode from "jwt-decode";

const INIT_STATE = {
    auth_user: localStorage.getItem('auth_user') != null ? JSON.parse(localStorage.getItem('auth_user')) : []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case AUTH_USER:
          
            return { ...state, loading: true }

        case AUTH_USER_SUCCESS:
       
            const expAt = jwt_decode(action.payload[0].accessToken).exp;
            localStorage.setItem('auth_user', JSON.stringify(action.payload))
            localStorage.setItem('expAt', expAt)
            return { ...state, loading: false, auth_user: action.payload }

        default: return { ...state };
    }
}
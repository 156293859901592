import {
  CUSTOMER_INVOICE_GET,
  CUSTOMER_INVOICE_GET_SUCCESS,
  NAME_TITLE_GET,
  NAME_TITLE_GET_SUCCESS,
  OMISE_SEND_CUSTOMER,
  OMISE_SEND,
  OMISE_SEND_SUCCESS,
  WIDTHHOLDINGTAX_GET,
  WIDTHHOLDINGTAX_GET_SUCCESS,
  BILL_SLIP_SEND,
  BILL_SLIP_INVOICE_SEND,
  BANK_PAYMENT_GET,
  BANK_PAYMENT_GET_SUCCESS,
} from "../actions";

const INIT_STATE = {
  customerINvoice: [],
  nameTitle: [],
  widthholdingtax: [],
  bankPayment: [],
  omiseSuccess: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case WIDTHHOLDINGTAX_GET:
      return { ...state, loading: true };

    case WIDTHHOLDINGTAX_GET_SUCCESS:
      return { ...state, loading: false, widthholdingtax: action.payload };

    case CUSTOMER_INVOICE_GET:
      return { ...state, loading: true };

    case CUSTOMER_INVOICE_GET_SUCCESS:
      return { ...state, loading: false, customerINvoice: action.payload };

    case NAME_TITLE_GET:
      return { ...state, loading: true };

    case NAME_TITLE_GET_SUCCESS:
      return { ...state, loading: false, nameTitle: action.payload };

    case BANK_PAYMENT_GET:
      return { ...state, loading: true };

    case BANK_PAYMENT_GET_SUCCESS:
      return { ...state, loading: false, bankPayment: action.payload };

    case OMISE_SEND_CUSTOMER:
      return { ...state, loading: false };

    case OMISE_SEND:
      return { ...state, loading: true };

    case OMISE_SEND_SUCCESS:
      return { ...state, loading: false, omiseSuccess: action.payload };

    case BILL_SLIP_SEND:
      return { ...state, loading: false };

    case BILL_SLIP_INVOICE_SEND:
      return { ...state, loading: false };

    default:
      return { ...state };
  }
};

import {
  CHANGE_LOCALE,
  COMPANY_PROFILE_ADD,
  COMPANY_PROFILE_ADD_SUCCESS,
  COMPANY_PROFILE_GET,
  COMPANY_PROFILE_GET_SUCCESS,
  COMPANY_PROFILE_UPDATE,
  COMPANY_PROFILE_UPDATE_SUCCESS,
  COMPANY_PROFILE_DELETE,
  COMPANY_PROFILE_DELETE_SUCCESS,
  COMPANY_PROFILE_COUNTRY,
  COMPANY_PROFILE_COUNTRY_SUCCESS,
  COMPANY_PROFILE_PROVINCE,
  COMPANY_PROFILE_PROVINCE_SUCCESS,
  COMPANY_PROFILE_DISTRICT,
  COMPANY_PROFILE_DISTRICT_SUCCESS,
  COMPANY_PROFILE_SUBDISTRICT,
  COMPANY_PROFILE_SUBDISTRICT_SUCCESS,
  // COMPANY_PROFILE_ZIPCODE,
  // COMPANY_PROFILE_ZIPCODE_SUCCESS,
  COMPANY_PKINDEV,
  COMPANY_PKINDEV_SUCCESS,
  PROVINCE,
  PROVINCE_SUCCESS,
  DISTRICT_SUCCESS,
  DISTRICT,
  SUBDISTRICT,
  SUBDISTRICT_SUCCESS,
  CREDITBANK_GET,
  CREDITBANK_GET_SUCCESS,
  PROJECT_ADD,
  PROJECT_ADD_SUCCESS,
  PROJECT_SET,
  PROJECT_SET_SUCCESS,
  PROJECT_GET,
  PROJECT_GET_INVOICE,
  PROJECT_GET_SUCCESS,
  PROJECT_UPDATE,
  PROJECT_UPDATE_SUCCESS,
  PROJECT_DELETE,
  PROJECT_DELETE_SUCCESS,
  PROJECT_COUNTRY,
  PROJECT_COUNTRY_SUCCESS,
  PROJECT_PROVINCE,
  PROJECT_PROVINCE_SUCCESS,
  PROJECT_DISTRICT,
  PROJECT_DISTRICT_SUCCESS,
  PROJECT_SUBDISTRICT,
  PROJECT_SUBDISTRICT_SUCCESS,
  USER_ADD,
  USER_GET,
  USER_GET_SUCCESS,
  ROLE_GET,
  ROLE_GET_SUCCESS,
  ROLE_ADD,
  ROLE_ADD_SUCCESS,
  USER_UPDATE,
  USER_PASS_UPDATE,
  USER_DELETE,
  ROLE_UPDATE,
  ROLE_UPDATE_SUCCESS,
  ROLE_DELETE,
  SEND_VERIFY,
  SEND_REST_PASSWORD,
  USER_GET_EMAIL,
  USER_GET_EMAIL_SUCCESS,
  CONFIRM_VERIFY,
  CONFIRM_VERIFY_STATUS,
  SEND_REQUEST,
  PAYMENT_GET,
  PAYMENT_GET_SUCCESS,
  PAYMENT_ADD,
  PAYMENT_DELETE,
} from "../actions";

export const changeLocale = (locale) => {
  localStorage.setItem("currentLanguage", locale);
  return {
    type: CHANGE_LOCALE,
    payload: locale,
  };
};

export const getCreditBank = (data) => {
  return {
    type: CREDITBANK_GET,
    payload: data,
  };
};

export const getCreditBankSuccess = (data) => {
  return {
    type: CREDITBANK_GET_SUCCESS,
    payload: data,
  };
};

export const getProvince1 = (data) => {
  return {
    type: PROVINCE,
    payload: data,
  };
};

export const getProvince1Success = (data) => {
  return {
    type: PROVINCE_SUCCESS,
    payload: data,
  };
};
export const getDistrict1 = (data) => {
  return {
    type: DISTRICT,
    payload: data,
  };
};

export const getDistrict1Success = (data) => {
  return {
    type: DISTRICT_SUCCESS,
    payload: data,
  };
};
export const getSubdistrict1 = (data) => {
  return {
    type: SUBDISTRICT,
    payload: data,
  };
};

export const getSubdistrict1Success = (data) => {
  return {
    type: SUBDISTRICT_SUCCESS,
    payload: data,
  };
};

export const addCompanyprofile = (data) => {
  return {
    type: COMPANY_PROFILE_ADD,
    payload: data,
  };
};

export const addCompanyprofileSuccess = (data) => {
  return {
    type: COMPANY_PROFILE_ADD_SUCCESS,
    payload: data,
  };
};

export const getCompanyPkindev = (data) => {
  return {
    type: COMPANY_PKINDEV,
    payload: data,
  };
};

export const getCompanyPkindevSuccess = (data) => {
  return {
    type: COMPANY_PKINDEV_SUCCESS,
    payload: data,
  };
};

export const getCompanyprofile = (data) => {
  return {
    type: COMPANY_PROFILE_GET,
    payload: data,
  };
};
export const getCompanyprofileSuccess = (data) => {
  return {
    type: COMPANY_PROFILE_GET_SUCCESS,
    payload: data,
  };
};
export const updateCompanyprofile = (data) => {
  return {
    type: COMPANY_PROFILE_UPDATE,
    payload: data,
  };
};
export const updateCompanyprofileSuccess = (data) => {
  return {
    type: COMPANY_PROFILE_UPDATE_SUCCESS,
    payload: data,
  };
};
export const delCompanyprofile = (data) => {
  return {
    type: COMPANY_PROFILE_DELETE,
    payload: data,
  };
};
export const delCompanyprofileSuccess = (data) => {
  return {
    type: COMPANY_PROFILE_DELETE_SUCCESS,
    payload: data,
  };
};
// ===============Address=============
export const countryAddress = (data) => ({
  type: COMPANY_PROFILE_COUNTRY,
  payload: data,
});
export const countryAddressSuccess = (data) => ({
  // console.log();
  type: COMPANY_PROFILE_COUNTRY_SUCCESS,
  payload: data,
});
export const provinceAddress = (data) => ({
  type: COMPANY_PROFILE_PROVINCE,
  payload: data,
});
export const provinceAddressSuccess = (data) => ({
  type: COMPANY_PROFILE_PROVINCE_SUCCESS,
  payload: data,
});
export const districtAddress = (data) => ({
  type: COMPANY_PROFILE_DISTRICT,
  payload: data,
});
export const districtAddressSuccess = (data) => ({
  type: COMPANY_PROFILE_DISTRICT_SUCCESS,
  payload: data,
});
export const subdistrictAddress = (data) => ({
  type: COMPANY_PROFILE_SUBDISTRICT,
  payload: data,
});
export const subdistrictAddressSuccess = (data) => ({
  type: COMPANY_PROFILE_SUBDISTRICT_SUCCESS,
  payload: data,
});
//   export const zipcodeAddress = (subdistrict) => ({
//     type: COMPANY_PROFILE_ZIPCODE,
//     payload: { subdistrict: subdistrict }
//   });
//   export const zipcodeAddressSuccess = (data) => ({
//     type: COMPANY_PROFILE_ZIPCODE_SUCCESS,
//     payload: data
//   });

// ======================Project====================

export const addProject = (data) => {
  return {
    type: PROJECT_ADD,
    payload: data,
  };
};

export const addProjectSuccess = (data) => {
  return {
    type: PROJECT_ADD_SUCCESS,
    payload: data,
  };
};

export const setProject = (data) => {
  return {
    type: PROJECT_SET,
    payload: data,
  };
};
export const setProjectSuccess = (data) => {
  return {
    type: PROJECT_SET_SUCCESS,
    payload: data,
  };
};
export const getProject = (data) => {
  return {
    type: PROJECT_GET,
    payload: data,
  };
};
export const getProjectInvoice = (data) => {
  return {
    type: PROJECT_GET_INVOICE,
    payload: data,
  };
};
export const getProjectSuccess = (data) => {
  return {
    type: PROJECT_GET_SUCCESS,
    payload: data,
  };
};
export const updateProject = (data) => {
  return {
    type: PROJECT_UPDATE,
    payload: data,
  };
};
export const updateProjectSuccess = (data) => {
  return {
    type: PROJECT_UPDATE_SUCCESS,
    payload: data,
  };
};
export const delProject = (data) => {
  return {
    type: PROJECT_DELETE,
    payload: data,
  };
};
export const delProjectSuccess = (data) => {
  return {
    type: PROJECT_DELETE_SUCCESS,
    payload: data,
  };
};

// ======================User====================

export const getUser = (data) => {
  return {
    type: USER_GET,
    payload: data,
  };
};
export const getUserEmail = (data) => {
  return {
    type: USER_GET_EMAIL,
    payload: data,
  };
};
export const getUserEmailSuccess = (data) => {
  return {
    type: USER_GET_EMAIL_SUCCESS,
    payload: data,
  };
};
export const getUserSuccess = (data) => {
  return {
    type: USER_GET_SUCCESS,
    payload: data,
  };
};
export const getRole = (data) => {
  return {
    type: ROLE_GET,
    payload: data,
  };
};
export const getRoleSuccess = (data) => {
  return {
    type: ROLE_GET_SUCCESS,
    payload: data,
  };
};
export const addRole = (data) => {
  return {
    type: ROLE_ADD,
    payload: data,
  };
};
export const addRoleSuccess = (data) => {
  return {
    type: ROLE_ADD_SUCCESS,
    payload: data,
  };
};

export const editRole = (data) => {
  return {
    type: ROLE_UPDATE,
    payload: data,
  };
};
export const editRoleSuccess = (data) => {
  return {
    type: ROLE_UPDATE_SUCCESS,
    payload: data,
  };
};
export const delRole = (data) => {
  return {
    type: ROLE_DELETE,
    payload: data,
  };
};
export const addUser = (data) => {
  return {
    type: USER_ADD,
    payload: data,
  };
};
export const updateUser = (data) => {
  return {
    type: USER_UPDATE,
    payload: data,
  };
};
export const updateUserPass = (data) => {
  return {
    type: USER_PASS_UPDATE,
    payload: data,
  };
};
export const delUser = (data) => {
  return {
    type: USER_DELETE,
    payload: data,
  };
};
export const sendVerify = (data) => {
  return {
    type: SEND_VERIFY,
    payload: data,
  };
};
export const sendRestPass = (data) => {
  return {
    type: SEND_REST_PASSWORD,
    payload: data,
  };
};
export const confirmVerify = (data) => {
  return {
    type: CONFIRM_VERIFY,
    payload: data,
  };
};
export const confirmVerifySuccess = (data) => {
  return {
    type: CONFIRM_VERIFY_STATUS,
    payload: data,
  };
};
export const sendRequest = (data) => {
  return {
    type: SEND_REQUEST,
    payload: data,
  };
};

// ======================Payment====================

export const addPayment = (data) => {
  return {
    type: PAYMENT_ADD,
    payload: data,
  };
};
export const deletePayment = (data) => {
  return {
    type: PAYMENT_DELETE,
    payload: data,
  };
};
export const getPayment = (data) => {
  return {
    type: PAYMENT_GET,
    payload: data,
  };
};
export const getPaymentSuccess = (data) => {
  return {
    type: PAYMENT_GET_SUCCESS,
    payload: data,
  };
};

import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Col,
  Row,
  Form,
  Container,
  Card,
  Image,
} from "react-bootstrap";
import Chip from "@material-ui/core/Chip";
import Icon from "@material-ui/core/Icon";
import styled from "styled-components";
import QRCode from "qrcode.react";
import Barcode from "react-barcode";
import { toAbsoluteUrl } from "../../helpers/Utils";
import $ from "jquery";
import {
  provinceAddress,
  districtAddress,
  subdistrictAddress,
} from "../../redux/actions";
import { connect } from "react-redux";

const StyledWrapper = styled.div`
  @media (max-width: 992px) {
    .policy_content {
      transform: scale(0.6);
      transform-origin: 0 0;
      margin-bottom: -120%;
    }
    @media (max-width: 425px) {
      .policy_content {
        transform: scale(0.4);
        margin-bottom: -280%;
      }
      @media (max-width: 375px) {
        .policy_content {
          margin-bottom: -330%;
        }
        @media (max-width: 320px) {
          .policy_content {
            margin-bottom: -410%;
          }
        }
      }
    }
  }
`;

const ducumentText = (text, id) => {
  const data = document.getElementById(id);
  if (data != null) data.innerHTML = text;
};

const set_date = (data) => {
  let date = new Date();
  if (data != "") date = new Date(data);
  const time1 = [
    Intl.DateTimeFormat("en-US", { day: "2-digit" }).format(date),
    Intl.DateTimeFormat("en-US", { month: "2-digit" }).format(date),
    Intl.DateTimeFormat("en-US", { year: "numeric" }).format(date),
  ];
  return time1.join("-");
};

const ModalPolicy = (props) => {
  const [dataInfoCompany, setdataInfoCompany] = useState(props.company);

  useEffect(() => {
    if (props.show) {
      ducumentText(props.content.text, "text_description");
      setdataInfoCompany(props.company);
    }
  }, [props.show]);

  return (
    <div>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        className={"policy"}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ color: "#003581" }}
          >
            {props.subject}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StyledWrapper>
            <div
              style={{ overflowY: "auto", maxHeight: "400px", padding: "15px" }}
            >
              <Row
                className="policy_content"
                style={{
                  border: "1px solid black",
                  minWidth: "900px",
                }}
              >
                <Col style={{ borderBottom: "1px solid black" }}>
                  <p
                    style={{
                      marginTop: "25px",
                      fontSize: "12.5px",
                      whiteSpace: "nowrap",
                      color: "black",
                    }}
                  >
                    {dataInfoCompany.name_company_en.toUpperCase()}
                    <br />
                    {dataInfoCompany.name_company}
                    <br />
                    {`${dataInfoCompany.address_en}, ${
                      props.selectSubDistrict.length != 0 &&
                      dataInfoCompany.id_subdistrict != ""
                        ? props.selectSubDistrict.find(
                            (doc) => doc.value == dataInfoCompany.id_subdistrict
                          ).label1
                        : null
                    }, ${
                      props.selectDistrict.length != 0 &&
                      dataInfoCompany.id_district != ""
                        ? props.selectDistrict
                            .find(
                              (doc) => doc.value == dataInfoCompany.id_district
                            )
                            .label1.split("Amphoe")[1]
                        : null
                    }, ${
                      props.selectProvince.length != 0 &&
                      dataInfoCompany.id_province != ""
                        ? props.selectProvince.find(
                            (doc) => doc.value == dataInfoCompany.id_province
                          ).label1
                        : null
                    }  Thailand ${dataInfoCompany.postal_code} `}
                    <br />
                    {`${dataInfoCompany.address} ตำบล${
                      props.selectSubDistrict.length != 0 &&
                      dataInfoCompany.id_subdistrict != ""
                        ? props.selectSubDistrict.find(
                            (doc) => doc.value == dataInfoCompany.id_subdistrict
                          ).label2
                        : null
                    } ${
                      props.selectDistrict.length != 0 &&
                      dataInfoCompany.id_district != ""
                        ? props.selectDistrict.find(
                            (doc) => doc.value == dataInfoCompany.id_district
                          ).label2
                        : null
                    } จังหวัด${
                      props.selectProvince.length != 0 &&
                      dataInfoCompany.id_province != ""
                        ? props.selectProvince.find(
                            (doc) => doc.value == dataInfoCompany.id_province
                          ).label2
                        : null
                    } ${dataInfoCompany.postal_code}`}
                    <br />
                    {`Tel : ${dataInfoCompany.phone_no} Fax : ${dataInfoCompany.fax_no}`}
                    <br />
                    {`เลขประจําตัวผู้เสียภาษีอากร : ${
                      dataInfoCompany.id_tax
                    } สาขาประกอบการ ${
                      dataInfoCompany.head_office
                        ? "สํานักงานใหญ่"
                        : "สํานักงานสาขา"
                    }`}
                  </p>
                </Col>
                <Col
                  style={{
                    borderLeft: "1px solid black",
                    borderBottom: "1px solid black",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <div style={{ height: "70px" }}>
                    <Image
                      className="mt-1"
                      src={dataInfoCompany.icon_company_url}
                      style={{ maxWidth: "100%", maxHeight: "80px" }}
                    />
                    <Image
                      className="mt-1"
                      src={dataInfoCompany.textlogo_company_url}
                      style={{ maxWidth: "45%", maxHeight: "100px" }}
                    />
                  </div>
                  <hr
                    style={{
                      borderColor: "black",
                      width: "106.5%",
                      marginLeft: "-3.2%",
                    }}
                  />
                  <div>
                    <p
                      style={{
                        marginTop: "25px",
                        fontSize: "12.5px",
                        whiteSpace: "nowrap",
                        color: "black",
                      }}
                    >
                      {props.text.en} <br /> {props.text.th}
                    </p>
                  </div>
                </Col>
                <Col xs={12} style={{ borderBottom: "1px solid black" }}>
                  <div style={{ minHeight: "1200px" }}>
                    <p
                      id="text_description"
                      style={{
                        padding: "2%",
                        wordWrap: "break-word",
                        color: "black",
                      }}
                    ></p>
                  </div>
                </Col>
                <Col xs={4} style={{ borderRight: " 1px solid black" }}>
                  <div style={{ height: "120px" }}>
                    <p
                      style={{
                        marginTop: "25px",
                        fontSize: "12.5px",
                        whiteSpace: "nowrap",
                        color: "black",
                      }}
                    >
                      {dataInfoCompany.name_company}
                      <div>
                        <Image
                          src={dataInfoCompany.authsignature_company_url}
                          style={{
                            width: "60px",
                            position: "absolute",
                            marginLeft: "35%",
                            marginTop: "2%",
                          }}
                        />
                        <Image
                          src={dataInfoCompany.stamp_company_url}
                          style={{
                            width: "140px",
                            position: "absolute",
                            marginLeft: "55%",
                            marginTop: "-4%",
                          }}
                        />
                      </div>
                      <div
                        className="mt-5"
                        style={{ borderTop: "2px dotted  black" }}
                      >
                        <p className="mt-3">
                          <span
                            style={{
                              position: "absolute",
                              marginLeft: "35%",
                              marginTop: "-1%",
                            }}
                          >
                            <b>{set_date(props.content.date)}</b>
                          </span>
                          วันที่……………………………………………………………………
                        </p>
                      </div>
                    </p>
                  </div>
                </Col>
                <Col xs={4} style={{ borderRight: " 1px solid black" }}>
                  <div style={{ height: "120px" }}>
                    <p
                      style={{
                        marginTop: "25px",
                        fontSize: "12.5px",
                        whiteSpace: "nowrap",
                        color: "black",
                      }}
                    >
                      รับทราบโดยกรรมการผู้มีอำนาจ
                      <div
                        className="mt-5"
                        style={{ borderTop: "2px dotted  black" }}
                      >
                        <p className="mt-3">วันที่……………………………………………………………………</p>
                      </div>
                    </p>
                  </div>
                </Col>
                <Col xs={4}>
                  <div style={{ height: "120px" }}>
                    <p
                      style={{
                        marginTop: "25px",
                        fontSize: "12.5px",
                        whiteSpace: "nowrap",
                        color: "black",
                      }}
                    >
                      ตราประทับบริษัทฯ (ถ้ามี)
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </StyledWrapper>
        </Modal.Body>
        <Modal.Footer>
          <Button
            name="btn_close"
            variant="light"
            onClick={props.onHide}
            style={{ color: "#003581", borderRadius: "10px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const PolicyPage = (props) => {
  let dataInfoCompany = props.data;
  ducumentText(props.content.text, props.policy_type);
  return (
    <div style={{ padding: "15px" }}>
      <Row
        className="policy_content"
        style={{ border: "1px solid black", minWidth: "900px" }}
      >
        <Col style={{ borderBottom: "1px solid black" }}>
          <p
            style={{
              marginTop: "25px",
              fontSize: "12.5px",
              whiteSpace: "nowrap",
              color: "black",
            }}
          >
            {dataInfoCompany.name_company_en.toUpperCase()}
            <br />
            {dataInfoCompany.name_company}
            <br />
            {`${dataInfoCompany.address_en}, ${
              props.selectSubDistrict.length != 0 &&
              dataInfoCompany.id_subdistrict != ""
                ? props.selectSubDistrict.find(
                    (doc) => doc.value == dataInfoCompany.id_subdistrict
                  ).label1
                : null
            }, ${
              props.selectDistrict.length != 0 &&
              dataInfoCompany.id_district != ""
                ? props.selectDistrict
                    .find((doc) => doc.value == dataInfoCompany.id_district)
                    .label1.split("Amphoe")[1]
                : null
            }, ${
              props.selectProvince.length != 0 &&
              dataInfoCompany.id_province != ""
                ? props.selectProvince.find(
                    (doc) => doc.value == dataInfoCompany.id_province
                  ).label1
                : null
            }  Thailand ${dataInfoCompany.postal_code} `}
            <br />
            {`${dataInfoCompany.address} ตำบล${
              props.selectSubDistrict.length != 0 &&
              dataInfoCompany.id_subdistrict != ""
                ? props.selectSubDistrict.find(
                    (doc) => doc.value == dataInfoCompany.id_subdistrict
                  ).label2
                : null
            } ${
              props.selectDistrict.length != 0 &&
              dataInfoCompany.id_district != ""
                ? props.selectDistrict.find(
                    (doc) => doc.value == dataInfoCompany.id_district
                  ).label2
                : null
            } จังหวัด${
              props.selectProvince.length != 0 &&
              dataInfoCompany.id_province != ""
                ? props.selectProvince.find(
                    (doc) => doc.value == dataInfoCompany.id_province
                  ).label2
                : null
            } ${dataInfoCompany.postal_code}`}
            <br />
            {`Tel : ${dataInfoCompany.phone_no} Fax : ${dataInfoCompany.fax_no}`}
            <br />
            {`เลขประจําตัวผู้เสียภาษีอากร : ${
              dataInfoCompany.id_tax
            } สาขาประกอบการ ${
              dataInfoCompany.head_office ? "สํานักงานใหญ่" : "สํานักงานสาขา"
            }`}
          </p>
        </Col>
        <Col
          style={{
            borderLeft: "1px solid black",
            borderBottom: "1px solid black",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div style={{ height: "70px" }}>
            <Image
              className="mt-1"
              src={dataInfoCompany.icon_company_url}
              style={{ maxWidth: "100%", maxHeight: "80px" }}
            />
            <Image
              className="mt-1"
              src={dataInfoCompany.textlogo_company_url}
              style={{ maxWidth: "35%", maxHeight: "100px" }}
            />
          </div>
          <hr
            style={{
              borderColor: "black",
              width: "106.5%",
              marginLeft: "-3.2%",
            }}
          />
          <div>
            <p
              style={{
                marginTop: "25px",
                fontSize: "12.5px",
                whiteSpace: "nowrap",
                color: "black",
              }}
            >
              {props.text.en} <br /> {props.text.th}
            </p>
          </div>
        </Col>
        <Col xs={12} style={{ borderBottom: "1px solid black" }}>
          <div style={{ minHeight: "1200px" }}>
            <p
              id={props.policy_type}
              style={{
                padding: "2%",
                wordWrap: "break-word",
                color: "black",
              }}
            ></p>
          </div>
        </Col>
        <Col xs={4} style={{ borderRight: " 1px solid black" }}>
          <div style={{ height: "120px" }}>
            <p
              style={{
                marginTop: "25px",
                fontSize: "12.5px",
                whiteSpace: "nowrap",
                color: "black",
              }}
            >
              {dataInfoCompany.name_company}
              <div>
                <Image
                  src={dataInfoCompany.authsignature_company_url}
                  style={{
                    width: "60px",
                    position: "absolute",
                    marginLeft: "35%",
                    marginTop: "2%",
                  }}
                />
                <Image
                  src={dataInfoCompany.stamp_company_url}
                  style={{
                    width: "140px",
                    position: "absolute",
                    marginLeft: "55%",
                    marginTop: "-4%",
                  }}
                />
              </div>
              <div className="mt-5" style={{ borderTop: "2px dotted  black" }}>
                <p className="mt-3">
                  <span
                    style={{
                      position: "absolute",
                      marginLeft: "35%",
                      marginTop: "-1%",
                    }}
                  >
                    <b>{set_date(props.content.date)}</b>
                  </span>
                  วันที่……………………………………………………………………
                </p>
              </div>
            </p>
          </div>
        </Col>
        <Col xs={4} style={{ borderRight: " 1px solid black" }}>
          <div style={{ height: "120px" }}>
            <p
              style={{
                marginTop: "25px",
                fontSize: "12.5px",
                whiteSpace: "nowrap",
                color: "black",
              }}
            >
              รับทราบโดยกรรมการผู้มีอำนาจ
              <div className="mt-5" style={{ borderTop: "2px dotted  black" }}>
                <p className="mt-3">วันที่……………………………………………………………………</p>
              </div>
            </p>
          </div>
        </Col>
        <Col xs={4}>
          <div style={{ height: "120px" }}>
            <p
              style={{
                marginTop: "25px",
                fontSize: "12.5px",
                whiteSpace: "nowrap",
                color: "black",
              }}
            >
              ตราประทับบริษัทฯ (ถ้ามี)
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ settings }) => {
  const { loading, selectProvince, selectDistrict, selectSubDistrict } =
    settings;
  return {
    loading,
    selectProvince,
    selectDistrict,
    selectSubDistrict,
  };
};

export default connect(mapStateToProps, {
  provinceAddress,
  districtAddress,
  subdistrictAddress,
})(ModalPolicy);

import axios from "axios";
// const BASE_URL = $('meta[name="hostname"]').attr('content');
// const BASE_URL =
//   "http://localhost:5000/api-pkindev-platform/asia-southeast2/pkindev";
const BASE_URL =
  "https://asia-southeast2-api-pkindev-platform.cloudfunctions.net/pkindev";

let HEADER = {
  "Content-Type": "application/json",
  withCredentials: true,
};

export class Provider {
  api(options) {
    return new Promise(async function (resolve, reject) {
      let { type, url, data, token } = options; //id
      let dataRes;
      if (typeof token != undefined)
        HEADER = {
          ...HEADER,
          "x-has-accesstoken": token,
        };
      const instanceMain1 = axios.create({
        baseURL: BASE_URL,
        headers: HEADER,
        transformResponse: [
          function (data) {
            const _data = JSON.parse(data);
            if (_data === null) return _data;
            if (_data.msg !== undefined) {
              if (_data.msg === "ข้อมูลการ Authen ไม่ถูกต้อง") {
                window.location = BASE_URL + "/403";
              }
            }
            return _data;
          },
        ],
      });

      switch (type) {
        case "GET":
          dataRes = await instanceMain1.get(url, { params: data });
          break;
        case "SHOW":
          dataRes = await instanceMain1.get(url);
          break;
        case "POST":
          dataRes = await instanceMain1.post(url, data);
          break;
        case "PUT":
          dataRes = await instanceMain1.put(url, data);
          break;
        case "DELETE":
          dataRes = await instanceMain1.delete(url, { params: data });
          break;
        default:
          break;
      }
      resolve(dataRes.data);
    });
  }
}

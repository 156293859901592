import { Provider } from "../global/Provider-Api";

const provider = new Provider();

class apiSetting {
  // =========================Auth User========================
  async getAuth(value) {
    const dataRes = await provider.api({
      type: "POST",
      url: "/billing/user/auth_user",
      data: value,
    });
    return dataRes;
  }
  // =========================Company Setting========================
  async getCompanyPkindev(value) {
    const dataRes = await provider.api({
      type: "POST",
      url: "/billing/company/get_company_pkindev",
      data: value,
      // token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  async getCompanyProfile(value) {
    const dataRes = await provider.api({
      type: "POST",
      url: "/billing/company/get_company_profile",
      data: value,
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }

  // =========================Address=========================
  async countryAddress(value) {
    const dataRes = await provider.api({
      type: "GET",
      url: "/nationality",
      data: { lang: value },
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  async provinceAddress(value) {
    const dataRes = await provider.api({
      type: "GET",
      url: "/province",
      data: value,
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  async districtAddress(value) {
    const dataRes = await provider.api({
      type: "GET",
      url: "/province/district",
      data: value,
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  async subdistrictAddress(value) {
    const dataRes = await provider.api({
      type: "GET",
      url: "/province/sub_district",
      data: value,
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }

  // =========================Project==========================
  async addProject(value) {
    const dataRes = await provider.api({
      type: "POST",
      url: "billing/project/add_project",
      data: value,
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  async getProject(value) {
    const dataRes = await provider.api({
      type: "GET",
      url: "billing/project/get_company_project",
      data: { id_bill: value },
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  async getProjectInvoice(value) {
    const dataRes = await provider.api({
      type: "GET",
      url: "billing/project/get_company_project_invoice",
      data: { id_bill: value },
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  async setProject(value) {
    const dataRes = await provider.api({
      type: "GET",
      url: "billing/project/set_company_project",
      data: { id_project: value },
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  async delProject(value) {
    const dataRes = await provider.api({
      type: "DELETE",
      url: "/billing/project/delete_project",
      data: { id_bill_branch: value },
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  // =========================User==========================
  async getUser(value) {
    const dataRes = await provider.api({
      type: "GET",
      url: "/billing/user/get_user",
      data: { id_bill: value },
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  async getUserEmail(value) {
    const dataRes = await provider.api({
      type: "GET",
      url: "/billing/user/get_user_all_email",
    });
    return dataRes;
  }
  async addUser(value) {
    const dataRes = await provider.api({
      type: "POST",
      url: "/billing/user/add_user",
      data: value,
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  async updateUser(value) {
    const dataRes = await provider.api({
      type: "PUT",
      url: "/billing/user/update_user",
      data: value,
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  async editUserPass(value) {
    const dataRes = await provider.api({
      type: "PUT",
      url: "/billing/user/update_user_pass",
      data: value,
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  async delUser(value) {
    const dataRes = await provider.api({
      type: "DELETE",
      url: "/billing/user/delete_user",
      data: { id_user: value },
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  async getRole(value) {
    const dataRes = await provider.api({
      type: "GET",
      url: "/billing/user/get_role",
      data: { id_bill: value },
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  async addRole(value) {
    const dataRes = await provider.api({
      type: "POST",
      url: "/billing/user/add_role",
      data: value,
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  async editRole(value) {
    const dataRes = await provider.api({
      type: "PUT",
      url: "/billing/user/edit_role",
      data: value,
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  async delRole(value) {
    const dataRes = await provider.api({
      type: "DELETE",
      url: "/billing/user/delete_role",
      data: { id_role: value },
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  async sendMail(value) {
    const dataRes = await provider.api({
      type: "GET",
      url: "/billing/user/resendemail",
      data: value,
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  async sendMailRsetPass(value) {
    const dataRes = await provider.api({
      type: "GET",
      url: "/billing/user/forgetpassword",
      data: value,
    });
    return dataRes;
  }
  async verifyEmail(value) {
    const dataRes = await provider.api({
      type: "GET",
      url: "/billing/user/verifyemail",
      data: null,
      token: value,
    });
    return dataRes;
  }
  // =========================Payment==========================
  async getPayment(value) {
    const dataRes = await provider.api({
      type: "GET",
      url: "/billing/payment/get_payment",
      data: { id_bill: value },
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  async addPayment(value) {
    const dataRes = await provider.api({
      type: "POST",
      url: "/billing/payment/add_payment",
      data: value,
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  async deletePayment(value) {
    const dataRes = await provider.api({
      type: "DELETE",
      url: "/billing/payment/delete_payment",
      data: value,
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  async sendRequest(value) {
    const dataRes = await provider.api({
      type: "POST",
      url: "/billing/user/send_request",
      data: value,
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }
  async CreditBank(value) {
    const dataRes = await provider.api({
      type: "GET",
      url: "/creditbank",
      data: { lang: value },
    });
    return dataRes;
  }

  async CustomerInvoice(value) {
    const dataRes = await provider.api({
      type: "GET",
      url: "/billing/customer_invoice",
      data: value,
    });
    return dataRes;
  }
  async BankPayment(value) {
    const dataRes = await provider.api({
      type: "GET",
      url: "/billing/customer_invoice/bank_payment",
      data: value,
    });
    return dataRes;
  }

  async NameTitle(value) {
    const dataRes = await provider.api({
      type: "GET",
      url: "/nametitle",
      data: value,
    });
    return dataRes;
  }

  async WithHoldingTax(value) {
    const dataRes = await provider.api({
      type: "GET",
      url: "/invoice/holding_tax",
      data: value,
    });
    return dataRes;
  }

  async SendOmise(value) {
    const dataRes = await provider.api({
      type: "POST",
      url: "/billing/payment/omise_charges",
      data: value,
    });
    return dataRes;
  }
  async SendOmiseCustomer(value) {
    const dataRes = await provider.api({
      type: "POST",
      url: "/billing/payment/omise_customer",
      data: value,
      token: localStorage.getItem("Auth_Token"),
    });
    return dataRes;
  }

  async SendBillSlip(value) {
    const dataRes = await provider.api({
      type: "POST",
      url: "/billing/payment/add_bill_slip",
      data: value,
    });
    return dataRes;
  }

  async SendBillSlipInvoice(value) {
    const dataRes = await provider.api({
      type: "POST",
      url: "/billing/payment/add_bill_slip_invoice",
      data: value,
    });
    return dataRes;
  }
}

export default new apiSetting();
